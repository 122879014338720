
const baseURL = process.env.REACT_APP_API_BASE_URL

const actions = {
    getPageConfig: data => ({
        method: 'get',
        url: 'page/get-config'
    }),
    initSubscription: data => ({
        method: 'post',
        url: 'account/init-subscription'
    }),
    listenSubscription: data => ({
        method: 'post',
        url: 'account/listen-subscription'
    }),
    lookup: data => ({
       method: 'post',
       url: 'account/lookup'
    }),
    saveTraffic: data => ({
        method: 'post',
        url: 'page/save-traffic'
    }),
    verifyOTP: data => ({
        method: 'post',
        url: 'account/verify-otp'
    })
}

const callAPI =
    (data, type) => {
        var myHeaders = new Headers();
        let dt = actions[type](data);
        let body;


        if(!data.web_click_id)
            data.web_click_id = localStorage.getItem('dh_clickId');

        if(!data.config_id)
            data.config_id = localStorage.getItem('dh_configId')

        myHeaders.append("Content-Type", "application/json");
        body = JSON.stringify(data);

        var requestOptions = {
            method: dt.method,
            headers: myHeaders,
            redirect: 'follow'
        };
        if(body && dt.method != 'get'){
            requestOptions.body = body
        }
        if(dt.method == 'get' && data){
            const query = Object.keys(data)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                .join('&');
            dt.url += "?" + query;
        }

        return fetch(baseURL + dt.url, requestOptions)
            .then(resp => resp.json())
            .then(function (resp) {
                return resp;
            })
            .catch(function (error) {
                throw new Error(error);
            });
    };

export default callAPI;
