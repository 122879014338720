import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss";
import {useEffect, useState} from "react";
import callAPI from "../../api";
import {useSearchParams} from "react-router-dom";
import DownloadGeneric from "../../templates/DownloadGeneric";
import DownloadGenericTwo from "../../templates/DownloadGenericTwo";
import {useSystem} from '../../hooks/use-system';
import DownloadGenericVideo from '../../templates/DownloadGenericVideo';
import CheckoutOne from "../../templates/CheckoutOne";
import AntiFraud from "../../components/AntiFraud";
import React, {Fragment} from "react";

function Home() {

    const {config, language, setConfig, setLanguage, webClickId, setWebClickId} = useSystem();
    const [configCalled, setConfigCalled] = useState(false);
    const [errorMessage, setError] = useState(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!configCalled ) {
            callAPI({
                path: window.location.pathname
            }, 'getPageConfig').then((data) => {
                if (data.status === 'SUCCESS') {
                    const conf = data.payload.configuration
                    const detectedLang = detectLang(conf.supported_languages, conf.default_language)
                    if (Array.isArray(conf.texts)) {
                        const texts = conf.texts
                        conf.texts = {}
                        conf.texts[detectedLang] = texts
                    }
                    setLanguage(detectedLang)
                    setConfig(conf);
                    saveTraffic(conf.public_id, conf.flow);
                } else {
                    if (window.location.hostname === 'localhost') {
                        const conf = {
                            "public_id": "90ca4425-186b-4384-be7d-70caed37b297",
                            "template": "default",
                            "campaign": "0cc045ac-57b4-485d-b6a9-5fd4ff570d94",
                            "name": "Currently Running on Google",
                            "flow": "LOOKUP_2_SMS",
                            "approach": "DEFAULT",
                            "prep_step": false,
                            "supported_languages": {
                                "de": "German",
                                "fr": "French",
                                "it": "Italian"
                            },
                            "country_code": "ch",
                            "country_name": "Switzerland",
                            "logo_url": "https://d202f37y8mzl74.cloudfront.net/dh-games/page_configurations/2023/10/31/page_config_logo_-5394.png",
                            "logo_position": "LEFT",
                            "logo_size": "Small",
                            "logo_under_button": false,
                            "texts": {
                                "fr": [
                                    {
                                        "title": "Votre fichier est prêt à télécharger",
                                        "sec_title": "",
                                        "description": "<p>Saisissez votre num&eacute;ro de t&eacute;l&eacute;phone mobile pour acc&eacute;der &agrave; votre t&eacute;l&eacute;chargement :</p>",
                                        "paragraph_under_button": "",
                                        "button_text": "Continuer",
                                        "button_caption": ""
                                    },
                                    {
                                        "title": "Votre téléchargement est prêt",
                                        "sec_title": "",
                                        "description": "<p>Envoyez le court code d'acc&egrave;s par SMS</p>",
                                        "paragraph_under_button": "",
                                        "button_text": "Envoyer un SMS",
                                        "button_caption": ""
                                    }
                                ],
                                "de": [
                                    {
                                        "title": "Ihre Datei steht zum Download bereit",
                                        "sec_title": "",
                                        "description": "<p>Geben Sie Ihre Mobiltelefonnummer ein, um auf Ihren Download zuzugreifen:</p>",
                                        "paragraph_under_button": "",
                                        "button_text": "weitermachen",
                                        "button_caption": ""
                                    },
                                    {
                                        "title": "Ihr Download ist fertig",
                                        "sec_title": "test",
                                        "description": "<p>DER LETZTE SCHRITT</p>\n<p>Senden Sie den kurzen Zugangscode per SMS</p>",
                                        "paragraph_under_button": "test under",
                                        "button_text": "SMS senden",
                                        "button_caption": ""
                                    }
                                ],
                                "it": [
                                    {
                                        "title": "Il tuo file è pronto per essere scaricato",
                                        "sec_title": "",
                                        "description": "<p>Inserisci il tuo numero di cellulare per accedere al download:</p>",
                                        "paragraph_under_button": "",
                                        "button_text": "Continua",
                                        "button_caption": ""
                                    },
                                    {
                                        "title": "Il download è pronto",
                                        "sec_title": "",
                                        "description": "<p>Invia il codice di accesso breve tramite SMS</p>",
                                        "paragraph_under_button": "",
                                        "button_text": "Inviare SMS",
                                        "button_caption": ""
                                    }
                                ]
                            },
                            "titles_color": "#000000",
                            "text_color": "black",
                            "font_family": "",
                            "button_text_color": "white",
                            "button_primary_color": "#3E5FFA",
                            "background_color": "#E2F1FF",
                            "header_background_color": "#FFF",
                            "background_image": null,
                            "player_image": "https://d202f37y8mzl74.cloudfront.net/dh-games/page_configurations/2023/9/26/page_config_fi_-494.png",
                            "text_above_player": "",
                            "featured_image": "https://d202f37y8mzl74.cloudfront.net/dh-games/page_configurations/2023/9/26/page_config_fi_-494.png",
                            "bottom_text": "<p><span style=\"font-size: 8pt;\">Playnow bietet Ihnen Zugriff auf mehr als 70 Spiele und erleichtert Ihnen das Durchsuchen von Webportalen. Senden Sie SMS mit PLAY an 336. Um den Dienst zu registrieren oder zu nutzen, m&uuml;ssen Sie Abonnent/Kontoinhaber und &uuml;ber 18 Jahre alt sein oder &uuml;ber angemessene Abonnenten-/Kontoinhaberrechte verf&uuml;gen. Wenn Sie das Abonnement k&uuml;ndigen, senden Sie eine SMS mit dem Stichwort: STOP PLAY an 336 oder kontaktieren Sie uns &uuml;ber unsere Hotline: +41 445 816 275 oder per E-Mail an digitalize.ch@silverlines.info. Das Abo kostet 10 CHF pro Woche (2 SMS/Woche f&uuml;r Salt und Swisscom; 1 SMS/Woche f&uuml;r Sunrise).</span></p>",
                            "terms_text": "Geschäftsbedingungen",
                            "terms_url": "https://app.digitalizehub.com/pages/Switzerland/",
                            "policy_text": "",
                            "policy_url": "",
                            "terms_agreement_text": "",
                            "sticky_top_right_text": "",
                            "terms_checkbox_switch": false,
                            "terms_autocheck_with_click": false,
                            "default_terms_switch_status": false,
                            "append_terms_link_to_consent": false,
                            "keyword": "hub",
                            "show_carriers": false,
                            "show_rating": true,
                            "show_country_flag": true,
                            "layout": "FULL_WIDTH",
                            "boxed_background_color": "#ffffff",
                            "carriers": null,
                            "short_code": "336",
                            "validate_msisdn": true,
                            "disable_step_2": true,
                            "allow_cookies_consent": true
                        }
                        const detectedLang = detectLang(conf.supported_languages, null)
                        if (Array.isArray(conf.texts)) {
                            const texts = conf.texts
                            conf.texts = {}
                            conf.texts[detectedLang] = texts
                        }
                        setLanguage(detectedLang)
                        setConfig(conf);
                        saveTraffic(conf.public_id, conf.flow);
                    } else {
                        setError('Invalid Page')
                    }
                }

            }).catch(err => {
                console.log(err)
                setError('Unknown Error')
            })
        }
    }, [])

    const getBrowserLang = () => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        return browserLanguage.slice(0, 2);
    }
    const detectLang = (supported_languages, default_language) => {
        const supportedLangs = Object.keys(supported_languages)
        let detectedLang = supportedLangs
        const savedLanguage = localStorage.getItem('dh_lang');
        if (savedLanguage) {
            detectedLang = savedLanguage;
            console.log(`detected saved lang ${detectedLang}`)
        } else {
            if (default_language != null && default_language != '') {
                detectedLang = default_language
            } else {
                const browserLanguage = getBrowserLang()
                if (supportedLangs.includes(browserLanguage)) {
                    detectedLang = browserLanguage
                    console.log(`detected browser lang ${detectedLang}`)
                } else {
                    detectedLang = supportedLangs[0]
                    console.log(`Setting a default lang ${detectedLang}`)
                }
            }

        }
        return detectedLang
    }

    const saveTraffic = (configId, flow) => {
        let clickId = searchParams.get('gclid');
        if (clickId === null) {
            clickId = searchParams.get('wbraid');
            if (clickId === null) {
                clickId = searchParams.get('gbraid');
                if (clickId === null) {
                    clickId = searchParams.get('click_id')
                }
            }
        }

        if (clickId) {
            callAPI({
                click_id: clickId,
                config_id: configId,
                path: window.location.pathname,
                qs: window.location.search,
            }, 'saveTraffic').then((data) => {
                if (data.status === 'SUCCESS') {
                    localStorage.setItem('dh_clickId', data.payload.ref_id);
                    setWebClickId(data.payload.ref_id);
                    if (data.payload.sms_suffix) {
                        localStorage.setItem('dh_sms_suffix', data.payload.sms_suffix);
                    }
                    if (flow == 'INIT_MSISDN_REDIRECT') {
                        localStorage.setItem('dh_session_id', data.payload.session_id)
                        localStorage.setItem('dh_token', data.payload.token)
                    }
                }
            })
        }
    }

    useEffect(() => {
        try {
            if (config) {
                localStorage.setItem('dh_configId', config.public_id)
                if (config.country_name) {
                    const domainElms = window.location.host.split(".");
                    const domain = window.location.host.replace(domainElms.length > 2 ? domainElms[0] + "." : "", "");
                    const title = domain[0].toUpperCase() + domain.substring(1) + ' | ' + config.country_name;
                    document.title = title;
                }
            }
        } catch (e) {

        }

    }, [config])

    const getTemplate = (config) => {

        try {
            if (config.allow_cookies_consent) {
                // let cmpScriptFound = document.getElementById("Cookiebot");
                // let CookieDeclaration = document.getElementById("CookieDeclaration");
                //
                // if (!cmpScriptFound && !CookieDeclaration) {
                //
                //     let cmpScript = document.createElement("script");
                //     cmpScript.type = "text/javascript";
                //     cmpScript.id = "Cookiebot"
                //     cmpScript.src = "https://consent.cookiebot.com/uc.js"
                //     cmpScript.setAttribute("data-cbid", "13769137-a929-4d4a-9f38-bdc93d9e3114")
                //     cmpScript.setAttribute("async", '');
                //
                //
                //     let cmpPopupScript = document.createElement("script");
                //     cmpPopupScript.type = "text/javascript";
                //     cmpPopupScript.id = "CookieDeclaration"
                //     cmpPopupScript.src = "https://consent.cookiebot.com/13769137-a929-4d4a-9f38-bdc93d9e3114/cd.js"
                //     cmpPopupScript.setAttribute("async", '')
                //
                //
                //     document.head.appendChild(cmpScript);
                //     document.body.appendChild(cmpPopupScript);
                // }

                let cmpScriptFound = document.getElementById("inmobi");
                if (!cmpScriptFound) {
                    let cmpScript = document.createElement("script");
                    cmpScript.id = "inmobi"
                    cmpScript.type = "text/javascript";
                    cmpScript.setAttribute("async", true);
                    cmpScript.text = "(function() {\n" +
                        "  var host = 'getyourdownload.store';\n" +
                        "  var element = document.createElement('script');\n" +
                        "  var firstScript = document.getElementsByTagName('script')[0];\n" +
                        "  var url = 'https://cmp.inmobi.com'\n" +
                        "    .concat('/choice/', 'NXfz4QYdbt6Ws', '/', host, '/choice.js?tag_version=V3');\n" +
                        "  var uspTries = 0;\n" +
                        "  var uspTriesLimit = 3;\n" +
                        "  element.async = true;\n" +
                        "  element.type = 'text/javascript';\n" +
                        "  element.src = url;\n" +
                        "\n" +
                        "  firstScript.parentNode.insertBefore(element, firstScript);\n" +
                        "\n" +
                        "  function makeStub() {\n" +
                        "    var TCF_LOCATOR_NAME = '__tcfapiLocator';\n" +
                        "    var queue = [];\n" +
                        "    var win = window;\n" +
                        "    var cmpFrame;\n" +
                        "\n" +
                        "    function addFrame() {\n" +
                        "      var doc = win.document;\n" +
                        "      var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);\n" +
                        "\n" +
                        "      if (!otherCMP) {\n" +
                        "        if (doc.body) {\n" +
                        "          var iframe = doc.createElement('iframe');\n" +
                        "\n" +
                        "          iframe.style.cssText = 'display:none';\n" +
                        "          iframe.name = TCF_LOCATOR_NAME;\n" +
                        "          doc.body.appendChild(iframe);\n" +
                        "        } else {\n" +
                        "          setTimeout(addFrame, 5);\n" +
                        "        }\n" +
                        "      }\n" +
                        "      return !otherCMP;\n" +
                        "    }\n" +
                        "\n" +
                        "    function tcfAPIHandler() {\n" +
                        "      var gdprApplies;\n" +
                        "      var args = arguments;\n" +
                        "\n" +
                        "      if (!args.length) {\n" +
                        "        return queue;\n" +
                        "      } else if (args[0] === 'setGdprApplies') {\n" +
                        "        if (\n" +
                        "          args.length > 3 &&\n" +
                        "          args[2] === 2 &&\n" +
                        "          typeof args[3] === 'boolean'\n" +
                        "        ) {\n" +
                        "          gdprApplies = args[3];\n" +
                        "          if (typeof args[2] === 'function') {\n" +
                        "            args[2]('set', true);\n" +
                        "          }\n" +
                        "        }\n" +
                        "      } else if (args[0] === 'ping') {\n" +
                        "        var retr = {\n" +
                        "          gdprApplies: gdprApplies,\n" +
                        "          cmpLoaded: false,\n" +
                        "          cmpStatus: 'stub'\n" +
                        "        };\n" +
                        "\n" +
                        "        if (typeof args[2] === 'function') {\n" +
                        "          args[2](retr);\n" +
                        "        }\n" +
                        "      } else {\n" +
                        "        if(args[0] === 'init' && typeof args[3] === 'object') {\n" +
                        "          args[3] = Object.assign(args[3], { tag_version: 'V3' });\n" +
                        "        }\n" +
                        "        queue.push(args);\n" +
                        "      }\n" +
                        "    }\n" +
                        "\n" +
                        "    function postMessageEventHandler(event) {\n" +
                        "      var msgIsString = typeof event.data === 'string';\n" +
                        "      var json = {};\n" +
                        "\n" +
                        "      try {\n" +
                        "        if (msgIsString) {\n" +
                        "          json = JSON.parse(event.data);\n" +
                        "        } else {\n" +
                        "          json = event.data;\n" +
                        "        }\n" +
                        "      } catch (ignore) {}\n" +
                        "\n" +
                        "      var payload = json.__tcfapiCall;\n" +
                        "\n" +
                        "      if (payload) {\n" +
                        "        window.__tcfapi(\n" +
                        "          payload.command,\n" +
                        "          payload.version,\n" +
                        "          function(retValue, success) {\n" +
                        "            var returnMsg = {\n" +
                        "              __tcfapiReturn: {\n" +
                        "                returnValue: retValue,\n" +
                        "                success: success,\n" +
                        "                callId: payload.callId\n" +
                        "              }\n" +
                        "            };\n" +
                        "            if (msgIsString) {\n" +
                        "              returnMsg = JSON.stringify(returnMsg);\n" +
                        "            }\n" +
                        "            if (event && event.source && event.source.postMessage) {\n" +
                        "              event.source.postMessage(returnMsg, '*');\n" +
                        "            }\n" +
                        "          },\n" +
                        "          payload.parameter\n" +
                        "        );\n" +
                        "      }\n" +
                        "    }\n" +
                        "\n" +
                        "    while (win) {\n" +
                        "      try {\n" +
                        "        if (win.frames[TCF_LOCATOR_NAME]) {\n" +
                        "          cmpFrame = win;\n" +
                        "          break;\n" +
                        "        }\n" +
                        "      } catch (ignore) {}\n" +
                        "\n" +
                        "      if (win === window.top) {\n" +
                        "        break;\n" +
                        "      }\n" +
                        "      win = win.parent;\n" +
                        "    }\n" +
                        "    if (!cmpFrame) {\n" +
                        "      addFrame();\n" +
                        "      win.__tcfapi = tcfAPIHandler;\n" +
                        "      win.addEventListener('message', postMessageEventHandler, false);\n" +
                        "    }\n" +
                        "  };\n" +
                        "\n" +
                        "  makeStub();\n" +
                        "\n" +
                        "  var uspStubFunction = function() {\n" +
                        "    var arg = arguments;\n" +
                        "    if (typeof window.__uspapi !== uspStubFunction) {\n" +
                        "      setTimeout(function() {\n" +
                        "        if (typeof window.__uspapi !== 'undefined') {\n" +
                        "          window.__uspapi.apply(window.__uspapi, arg);\n" +
                        "        }\n" +
                        "      }, 500);\n" +
                        "    }\n" +
                        "  };\n" +
                        "\n" +
                        "  var checkIfUspIsReady = function() {\n" +
                        "    uspTries++;\n" +
                        "    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {\n" +
                        "      console.warn('USP is not accessible');\n" +
                        "    } else {\n" +
                        "      clearInterval(uspInterval);\n" +
                        "    }\n" +
                        "  };\n" +
                        "\n" +
                        "  if (typeof window.__uspapi === 'undefined') {\n" +
                        "    window.__uspapi = uspStubFunction;\n" +
                        "    var uspInterval = setInterval(checkIfUspIsReady, 6000);\n" +
                        "  }\n" +
                        "})();";

                    document.head.appendChild(cmpScript);

                }

            }
        } catch (e) {
            console.log("error in cmp script")
        }

        let template = <DownloadGeneric/>
        if (config.template === 'generic-download-2') {
            template = <DownloadGenericTwo/>
        } else if (config.template === 'generic-download-video') {
            template = <DownloadGenericVideo/>
        } else if (config.template === 'checkout-1') {
            template = <CheckoutOne/>
        }
        return template;
    }

    return (
        config && language ? (
            <Fragment>
                { config.anti_fraud_service ? (<AntiFraud antiFraudProvider={config.anti_fraud_service} pageName={config.name}/>) : ""}
                {getTemplate(config)}
            </Fragment>
        ) : (
            <div id={"loader"} className={"d-flex align-items-center vw-100 justify-content-center vh-100"}>
                <div className={'my-auto flex-column d-flex'}>

                    <h5>{errorMessage}</h5>

                    {!errorMessage && <>
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        {/*<div className="spinner-border spinner-border-sm text-dark"><span*/}
                        {/*    className="visually-hidden"> Loading...</span>*/}
                        {/*</div>*/}
                        {/*&nbsp;loading...*/}
                    </>}
                </div>
            </div>
        )
    )
}

export default Home;
